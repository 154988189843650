import Link from 'next/link'
import PropTypes from 'prop-types'
import { H5, Icon, MainButton, P2, useWindowSize } from '@moneymade/moneymade-ui'
import { useState } from 'react'

// import ImgLazy from 'components/ui/ImgLazy/ImgLazy'
import { ROUTES } from 'static/menusConstants'
import { setPrevPos } from 'utils'
import useTracker from 'hooks/useTracker'
import PlatformIndustry from 'components/ui/PlatformIndustry/PlatformIndustry'
import PlatformMeta from 'components/ui/PlatformMeta/PlatformMeta'
import PreviewModal from 'components/cards/InvestmentCard/PreviewModal/PreviewModal'
import { getCdnImg } from 'utils/getters'
import CustomTooltip from 'components/cards/InvestmentCard/CustomTooltip/CustomTooltip'

import styles from './InvestmentCard.module.scss'

const InvestmentCard = ({
  investment: {
    id,
    slug,
    name,
    logo,
    profile,
    averageRating,
    industry,
    collapsedDescription,
    minInvestment,
    target,
    actualReturn,
    industries,
    dataIndustry,
    media,
    brandVideo,
    promoVideo
  },
  inNewTab,
  industriesAvgAll
}) => {
  const { screenWidth } = useWindowSize()
  const { VisibilityTracker, HoverScrollTracker, handleUseTracker } = useTracker()
  const [isModal, setModal] = useState(false)

  const handleClickOnBtn = () => {
    setPrevPos('discover', document.documentElement.scrollTop)
    handleUseTracker('DetailsButtonClick', `${slug}`, `${slug}`)
  }
  const industryTop = dataIndustry?.dataSlug ? dataIndustry : industries[0]?.dataSlug ? industries[0] : industries[1]

  const handleOpenLink = event => {
    event.preventDefault()
    event.stopPropagation()

    handleUseTracker('InvestButtonClick', `${slug}`, `${slug}`)
    window.open(
      `${
        slug === 'magiceden'
          ? 'https://w.mmin.io/platform-click/coinbase-nft?utm_source=moneymade&utm_campaign=discover_coinbase-nft&utm_medium=website'
          : `https://w.mmin.io/platform-click/${slug}?utm_source=moneymade&utm_campaign=discover&utm_medium=investment_card`
      }`,
      '_blank'
    )
  }

  const handleOpenModal = event => {
    event.preventDefault()
    event.stopPropagation()

    handleUseTracker('PlatformVideoClick', 'platform', slug)

    setModal(true)
  }

  return (
    <section id={`card-${id}`}>
      <PreviewModal
        isModal={isModal}
        handleSetModal={setModal}
        url={brandVideo}
        promoVideo={promoVideo}
        website={`https://w.mmin.io/platform-click/${slug}?utm_source=moneymade&utm_campaign=discover&utm_medium=video_modal`}
        name={name}
      />

      <VisibilityTracker category="PlatformImpression" actionKey="platform" action={slug}>
        <HoverScrollTracker category="Platform" actionKey="platform" action={slug}>
          <Link href={`/${ROUTES.discover}/${slug === 'magiceden' ? 'coinbase-nft' : slug}`} prefetch={false}>
            <a
              className={styles.InvestmentCard}
              onClick={handleClickOnBtn}
              target={inNewTab ? '_blank' : '_self'}
              rel="noreferrer"
              aria-hidden
            >
              {(brandVideo || promoVideo) && screenWidth < 601 ? (
                <button type="button" className={styles.MobMediaBtn} onClick={handleOpenModal}>
                  <img src={getCdnImg(media[0], 600)} alt={name} width="100%" height="100%" />

                  <div className={styles.IconConatiner}>
                    <Icon icon="icon-play" size={16} />
                  </div>
                </button>
              ) : screenWidth < 601 ? (
                <div className={styles.MobMediaBtn}>
                  <img src={getCdnImg(media[0], 600)} alt={name} width="100%" height="100%" />
                </div>
              ) : null}

              <div className={styles.LeftSide}>
                <div className={styles.TopPart}>
                  <img src={getCdnImg(logo, 48)} alt={name} width={48} height={48} />

                  <div className={styles.MainInfo}>
                    <div className={styles.Title} onClick={handleOpenLink} role="presentation">
                      <H5 weight="semibold">{name}</H5>
                      <div className={styles.Icons}>
                        <Icon icon="icon-external-link" size={16} />
                      </div>
                      <div className={styles.Icons}>
                        {profile?.verificationStatus === 'Verified Paying (Premium)' && (
                          <CustomTooltip
                            title="Verified Platform"
                            tooltipText="The information on this platform has been verified for accuracy"
                            width="150px"
                            color="blue"
                          >
                            <svg
                              width="18"
                              height="18"
                              fill="#61DBFB"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path d="M256 0c36.8 0 68.8 20.7 84.9 51.1C373.8 41 411 49 437 75s34 63.3 23.9 96.1C491.3 187.2 512 219.2 512 256s-20.7 68.8-51.1 84.9C471 373.8 463 411 437 437s-63.3 34-96.1 23.9C324.8 491.3 292.8 512 256 512s-68.8-20.7-84.9-51.1C138.2 471 101 463 75 437s-34-63.3-23.9-96.1C20.7 324.8 0 292.8 0 256s20.7-68.8 51.1-84.9C41 138.2 49 101 75 75s63.3-34 96.1-23.9C187.2 20.7 219.2 0 256 0zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                            </svg>
                          </CustomTooltip>
                        )}
                      </div>
                    </div>

                    <PlatformMeta
                      averageRating={averageRating}
                      industry={screenWidth > 960 ? industry?.name : null}
                      // minInvestment={screenWidth > 960 ? minInvestment : null}
                      asset={dataIndustry?.name || ''}
                    />
                  </div>
                </div>

                <div className={styles.MobileCenterPart}>
                  <PlatformMeta
                    industry={industry?.name}
                    // minInvestment={minInvestment}
                    asset={dataIndustry?.name || ''}
                  />
                </div>

                <div className={styles.BottomPart}>
                  <P2>{collapsedDescription}</P2>
                </div>
              </div>

              <div className={styles.RightSide}>
                <div className={styles.TopPart}>
                  <PlatformIndustry
                    actualReturn={actualReturn}
                    nameplatform={name}
                    industryTop={industryTop}
                    minInvest={minInvestment}
                    avg={industriesAvgAll[industryTop?.dataSlug]}
                    target={target}
                    toLeft
                  />
                </div>

                <div className={styles.BottomPart}>
                  <MainButton
                    size="sm"
                    weight="normal"
                    fontType="main"
                    color="dark"
                    shape="squared"
                    onClick={handleOpenLink}
                  >
                    Visit Now
                  </MainButton>

                  <MainButton
                    size="sm"
                    weight="light"
                    fontType="main"
                    onClick={handleClickOnBtn}
                    className={styles.Details}
                  >
                    Learn More
                  </MainButton>
                </div>
              </div>
            </a>
          </Link>
        </HoverScrollTracker>
      </VisibilityTracker>
    </section>
  )
}

InvestmentCard.propTypes = {
  investment: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    slug: PropTypes.string,
    logo: PropTypes.string,
    averageRating: PropTypes.number,
    industry: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
    collapsedDescription: PropTypes.string,
    newRisks: PropTypes.arrayOf(PropTypes.string),
    minInvestment: PropTypes.number,
    fees: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    target: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string
    }),
    profile: PropTypes.shape({
      verificationStatus: PropTypes.string
    }),
    premium: PropTypes.number,
    actualReturn: PropTypes.number,
    profileViewsForLast30Days: PropTypes.number,
    popularityForPeriods: PropTypes.shape({
      '30d': PropTypes.number
    }),
    industries: PropTypes.arrayOf(PropTypes.shape({ dataSlug: PropTypes.string })),
    website: PropTypes.string,
    dataIndustry: PropTypes.shape({ name: PropTypes.string, dataSlug: PropTypes.string }),
    media: PropTypes.arrayOf(PropTypes.string),
    brandVideo: PropTypes.string,
    promoVideo: PropTypes.string
  }),
  inNewTab: PropTypes.bool,
  industriesAvgAll: PropTypes.shape({})
}

InvestmentCard.defaultProps = {
  investment: {
    id: '',
    name: '',
    slug: '',
    logo: '',
    averageRating: 0,
    industry: {},
    profile: {},
    collapsedDescription: '',
    newRisks: [],
    minInvestment: 0,
    fees: 0,
    actualReturn: 0,
    target: {
      min: '',
      max: ''
    },
    premium: 0,
    profileViewsForLast30Days: 0,
    popularityForPeriods: {
      '30d': 0
    },
    industries: [{ dataSlug: '' }],
    dataIndustry: [{ dataSlug: '', name: '' }],
    website: '',
    media: [],
    brandVideo: '',
    promoVideo: ''
  },
  inNewTab: false,
  industriesAvgAll: {}
}

export default InvestmentCard
